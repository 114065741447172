<template>
  <form class="debit-pull-contacts">
    <div class="widgetContainer widgetContainer--scrollable debitPull">
      <div class="navigationHeader">
        <div class="navigationHeader__inner">
          <p class="title">
            {{ $t('RCD_debitPull') }}
          </p>
          <span class="el-icon-close" @click="drawerClose" />
        </div>
      </div>

      <div class="widgetContainer__body">
        <Contacts @onContactClick="onContactClick($event)" ref="contacts" />
      </div>
    </div>
  </form>
</template>

<script>
import plaid from '../mixins/plaid';
import Contacts from '@m/contact/views/Contacts';
import {mapActions, mapMutations} from 'vuex';

export default {
  mixins: [plaid],
  components: {
    Contacts
  },
  data() {
    return {};
  },
  computed: {
  },
  mounted() {
  },
  created() {
  },
  methods: {
    ...mapActions('fund', ['createDebitCardToken']),
    ...mapMutations('contact', ['updateSelectedContactData']),
    onContactClick(contact) {
      this.updateSelectedContactData(contact);
      const loader = this.showLoader();

      this.createDebitCardToken(contact.id).then(() => {
        loader.close();
        this.$emit('goTo', 'DebitPull-form');
      }, (error) => {
        this.apiErrorMessage(error);
        loader.close();
      });
    }
  }
};
</script>

<style lang="scss">
.debit-pull-contacts {
  .widgetContainer__body {
    padding-top: 0!important;
  }
}
</style>
